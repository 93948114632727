<template>
  <div id="dmca" :class="is_mobile ? 'i_m' : 'i_pc'">

    <h2>DMCA Policy</h2>

    <p>All the content on gbapksdownload.com is either submitted to gbapksdownload.com by email or
      is readily available in various places on the Internet and believed to be in the public
      domain. Content (including images and videos) posted are believed to be posted within our
      rights according to the U.S. Copyright Fair Use Act (Title 17, U.S. Code.) Gbapps.net is Not
      the owner of Apps or Developer We are Sharing our Opinions about Apps If you Feel Any issue or
      Found Copyright Material in our website Please send the infringement notice via email to
      gbwhatsapp.urimods@gmail.com</p>

    <h3>Notification of Copyright Infringement</h3>

    <p>
      gbapksdownload.com is in compliance with 17 U.S.C. §512 and the Digital Millennium Copyright
      Act (“DMCA”). It is our policy to respond to any infringement notices and take appropriate
      actions under the Digital Millennium Copyright Act (“DMCA”) and other applicable intellectual
      property laws.
    </p>

    <p>
      If your copyrighted material has been posted on gbapksdownload.com or if links to your
      copyrighted material are returned through a search engine and you want this material removed,
      you must provide a written communication that details the information listed in the following
      section. Liable for damages (including costs and attorneys fees) if you misrepresent
      information listed on our site that is infringing on your copyrights. We suggest that you
      first contact an attorney for legal assistance on this matter. <br />

      The following elements must be included in your copyright infringement claim:<br />

      ~ Provide evidence of the authorized person to act on behalf of the owner of an exclusive
      right that is allegedly infringed.<br />

      ~You must identify in sufficient detail the copyrighted work claimed to have been infringed
      and include at least one search term under which the material appears in techbigs.com search
      results.<br />

      ~A statement that the complaining party has a good faith belief that the use of the material
      in the manner complained of is not authorized by the copyright owner, its agent, or the
      law.<br />

      ~A statement that the information in the notification is accurate, and under penalty of
      perjury, that the complaining party is authorized to act on behalf of the owner of an
      exclusive right that is allegedly infringed.
    </p>

    <p>
      Must be signed by the authorized person to act on behalf of the owner/Company head of an
      exclusive right that is allegedly being infringed. <br />
      Please send the infringement notice via email to gbwhatsapp.urimods@gmail.com
    </p>

    <p>
      Please allow us 3-5 business days for an email response.
      <br />
      Note that emailing your complaint to other parties such as our Internet Service Provider will
      not expedite your request and may result in a delayed response due to the complaint not
      properly being filed. Thanks.
    </p>

    <h3>Contact Information</h3>

    <p>If Have Any Issue with gbapksdownload.com the we are always here to assist you on Email. The
      only way To contact us through email here is our email: gbwhatsapp.urimods@gmail.com</p>

  </div>
</template>

<script>
import '@/css/main.scss'
import '@/css/dmca.scss'
import Tophead from '@/components/mobile/Tophead.vue'

export default {
  name: 'app',
  data () {
    return {
      screenWidth: null,
      is_mobile: null,
    }
  },
  components: {
    Tophead,
  },
  mounted () {
    console.log(process.env)
    console.log(this._isMobile())
    if (this._isMobile()) {
      this.is_mobile = true
    }
    this.$logEvent('show_dmcahome')
  },
  metaInfo () {
    return {
      title: 'DMCA',

    }
  },
  methods: {
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
  },
}
</script>
